<template>
  <div class="columns is-centered">
    <div class="column is-10 is-12-mobile">
      <v-form
        ref="editProfile"
        :initial-value="division"
        :on-submit="submitEditDivision"
        :on-submit-success="successSubmitEditDivision"
        :on-submit-error="errorSubmitEditDivision"
        :process-form="changeDateFormat"
      >
        <template v-slot="{ form }">
          <div class="container">
            <b-field
              :label="
                division.is_project
                  ? $t('editDivisionDetailHead.nameProjectInput')
                  : $t('editDivisionDetailHead.nameDivisionInput')
              "
              id="division-name"
              label-for="division-name-input"
            >
              <b-input
                v-model="form.values.name"
                id="division-name-input"
                size="is-medium"
              ></b-input>
            </b-field>

            <b-field
              :label="
                division.is_project
                  ? $t('editDivisionDetailHead.descriptionProjectInput')
                  : $t('editDivisionDetailHead.descriptionDivisionInput')
              "
              id="division-description"
              label-for="division-description-input"
            >
              <b-input
                v-model="form.values.description"
                id="division-description-input"
                type="textarea"
              ></b-input>
            </b-field>
            <b-field
              :label="
                division.is_project
                  ? $t('editDivisionDetailHead.shortNameProjectInput')
                  : $t('editDivisionDetailHead.shortNameDivisionInput')
              "
              id="division-short-name"
              label-for="division-short-name-input"
            >
              <b-input v-model="form.values.short_name" id="division-short-name-input"></b-input>
            </b-field>
          </div>
          <div class="mt-2" v-if="division.is_project">
            <div class="columns">
              <div class="column">
                <b-field
                  :label="$t('views.projectCreate.startDate')"
                  id="start-date-input"
                  :message="form.errors.start_date"
                  :type="form.types.start_date"
                >
                  <b-datepicker
                    :placeholder="$t('form.clickToSelect')"
                    :value="
                      form.values.start_date ? dateWithoutOffset(form.values.start_date) : null
                    "
                    @input="
                      (value) => {
                        form.values.start_date = value
                      }
                    "
                  >
                  </b-datepicker>
                </b-field>
              </div>

              <div class="column">
                <b-field
                  :label="$t('views.projectCreate.endDate')"
                  :message="form.errors.end_date"
                  :type="form.types.end_date"
                  id="end-date-input"
                >
                  <b-datepicker
                    :placeholder="$t('form.clickToSelect')"
                    :value="form.values.end_date ? dateWithoutOffset(form.values.end_date) : null"
                    @input="
                      (value) => {
                        form.values.end_date = value
                      }
                    "
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
          </div>
          <div class="buttons is-right mt-4">
            <b-button
              @click="callDeleteDivision"
              type="is-danger"
              v-if="person.creators_delete_division.includes(division.id)"
              >{{ $t('editDivisionDetailHead.deleteButton') }}</b-button
            >
            <b-button @click="$emit('doneEdit')">{{
              $t('personEditProfile.cancelButton')
            }}</b-button>
            <b-button native-type="submit" type="is-azul-imfd" :disabled="!!form.erorrs"
              >{{ $t('personEditProfile.saveButton') }}
            </b-button>
          </div>
        </template>
      </v-form>
    </div>
  </div>
</template>

<script>
import VForm from '@/components/VForm'
import { editDivision, deleteDivision } from '@/requests/divisions'
import AppError from '@/errors/AppError'
import { dateWithoutOffset, toBackendFormat } from '@/utils/date-utils'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('auth')

export default {
  name: 'EditDivisionDetailHead',
  props: {
    division: {
      type: Object,
      required: true
    }
  },
  components: {
    VForm
  },
  methods: {
    async submitEditDivision(form) {
      const response = await editDivision(form)
      if (response) {
        this.$emit('refetchDivision')
      }
      this.$emit('doneEdit')
    },
    dateWithoutOffset,
    successSubmitEditDivision() {
      this.$buefy.snackbar.open({
        message: this.division.is_project
          ? this.$t('editDivisionDetailHead.projectEditedSuccess')
          : this.$t('editDivisionDetailHead.divisionEditedSuccess'),
        type: 'is-success',
        position: 'is-top'
      })
    },
    errorSubmitEditDivision(error) {
      if (Object.prototype.hasOwnProperty.call(error, 'report')) {
        this.$buefy.toast.open({
          message: error.report.detail,
          type: 'is-danger'
        })
      }
    },
    changeDateFormat(form) {
      if (this.division.is_project) {
        form.start_date = form.start_date ? toBackendFormat(new Date(form.start_date)) : null
        form.end_date = form.end_date ? toBackendFormat(new Date(form.end_date)) : null
      } else {
        delete form['start_date']
        delete form['end_date']
      }
      return form
    },
    async callDeleteDivision() {
      try {
        await deleteDivision({ divisionId: this.division.id })
        this.$buefy.snackbar.open({
          message: this.division.is_project
            ? this.$t('editDivisionDetailHead.projectDeletedSuccess')
            : this.$t('editDivisionDetailHead.divisionDeletedSuccess'),
          type: 'is-success',
          position: 'is-top'
        })
        this.$router.go(-1)
      } catch (err) {
        let msg
        if (err instanceof AppError) {
          msg = err.message
        } else {
          msg = err.report.detail
        }
        this.$buefy.toast.open({
          message: msg,
          type: 'is-danger'
        })
        this.$emit('doneEdit')
      }
    }
  },
  computed: {
    ...mapState(['person'])
  }
}
</script>
