import i18n from '@/i18n'

export const dateWithoutOffset = (date) => {
  const localDate = new Date(date)
  return new Date(Date.parse(localDate) + localDate.getTimezoneOffset() * 60 * 1000)
}

export const toFrontendShowDateFormat = (date) => {
  return new Date(dateWithoutOffset(date)).toLocaleDateString(i18n.locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

export const toBackendFormat = (localDate) => {
  const date = dateWithoutOffset(localDate)
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}
