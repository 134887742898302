<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li
        v-for="(division, index) in divisions"
        :key="division.id"
        :class="[divisions.length - 1 === index ? 'is-active' : false]"
      >
        <router-link :to="{ name: 'division', params: { id: division.id } }">
          {{ divisions.length > 3 ? division.short_name : division.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    divisions: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      promises: null,
      editDivision: false
    }
  }
}
</script>
