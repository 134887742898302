<template>
  <v-promised :promise="promises">
    <template #pending>
      <div>
        <b-loading :can-cancel="false" active :is-full-page="false"></b-loading>
      </div>
    </template>
    <template #default="[division, projects, members, breadcrumbs]">
      <div class="" v-if="!editDivision">
        <division-detail-head
          :division="division"
          :projects="projects.length"
          :members="members.length"
          :person="person"
        />
      </div>
      <div class="" v-else>
        <edit-division-detail-head
          :division="division"
          :projects="projects.length"
          :members="members.length"
          @doneEdit="editDivision = false"
          @refetchDivision="refetchDivision"
        />
      </div>
      <div class="bottom-container">
        <div class="container">
          <breadcrumbs :divisions="breadcrumbs" />
          <division-detail-body
            :division="division"
            :projects="projects"
            :members="members"
            @refetchDivision="refetchDivision"
            @refetchMembers="refetchMembers"
          />
        </div>
      </div>
    </template>
    <template #rejected="error">
      <v-fail :error="error" />
    </template>
  </v-promised>
</template>

<script>
import { findMembers, findProjects, findSubdivisions, getBreadcrumbs } from '@/requests/divisions'
import DivisionDetailHead from '@/components/DivisionDetailHead.vue'
import EditDivisionDetailHead from '@/components/EditDivisionDetailHead.vue'
import DivisionDetailBody from '@/components/DivisionDetailBody.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('auth')

export default {
  name: 'DivisionDetail',
  components: {
    DivisionDetailHead,
    DivisionDetailBody,
    EditDivisionDetailHead,
    Breadcrumbs
  },
  data() {
    return {
      promises: null,
      editDivision: false
    }
  },
  async mounted() {
    this.promises = Promise.all([
      findSubdivisions({ divisionId: this.$route.params.id }),
      findProjects({ divisionId: this.$route.params.id }),
      findMembers({ divisionId: this.$route.params.id }),
      getBreadcrumbs({ divisionId: this.$route.params.id })
    ])
  },
  methods: {
    async refetchDivision(divisionEdited) {
      const [division, projects, members, breadcrumbs] = await this.promises

      this.promises = Promise.all([
        divisionEdited
          ? Promise.resolve({ ...division, ...divisionEdited })
          : findSubdivisions({ divisionId: this.$route.params.id }),
        Promise.resolve(projects),
        Promise.resolve(members),
        Promise.resolve(breadcrumbs)
      ])
    },
    async refetchMembers() {
      const [division, projects, , breadcrumbs] = await this.promises

      this.promises = Promise.all([
        Promise.resolve(division),
        Promise.resolve(projects),
        findMembers({ divisionId: this.$route.params.id }),
        Promise.resolve(breadcrumbs)
      ])
    }
  },
  computed: {
    ...mapState(['person'])
  }
}
</script>

<style scoped>
.bottom-container {
  padding: 3em 0;
  background: white;
}
</style>
