var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-10 is-12-mobile"},[_c('v-form',{ref:"editProfile",attrs:{"initial-value":_vm.division,"on-submit":_vm.submitEditDivision,"on-submit-success":_vm.successSubmitEditDivision,"on-submit-error":_vm.errorSubmitEditDivision,"process-form":_vm.changeDateFormat},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var form = ref.form;
return [_c('div',{staticClass:"container"},[_c('b-field',{attrs:{"label":_vm.division.is_project
                ? _vm.$t('editDivisionDetailHead.nameProjectInput')
                : _vm.$t('editDivisionDetailHead.nameDivisionInput'),"id":"division-name","label-for":"division-name-input"}},[_c('b-input',{attrs:{"id":"division-name-input","size":"is-medium"},model:{value:(form.values.name),callback:function ($$v) {_vm.$set(form.values, "name", $$v)},expression:"form.values.name"}})],1),_c('b-field',{attrs:{"label":_vm.division.is_project
                ? _vm.$t('editDivisionDetailHead.descriptionProjectInput')
                : _vm.$t('editDivisionDetailHead.descriptionDivisionInput'),"id":"division-description","label-for":"division-description-input"}},[_c('b-input',{attrs:{"id":"division-description-input","type":"textarea"},model:{value:(form.values.description),callback:function ($$v) {_vm.$set(form.values, "description", $$v)},expression:"form.values.description"}})],1),_c('b-field',{attrs:{"label":_vm.division.is_project
                ? _vm.$t('editDivisionDetailHead.shortNameProjectInput')
                : _vm.$t('editDivisionDetailHead.shortNameDivisionInput'),"id":"division-short-name","label-for":"division-short-name-input"}},[_c('b-input',{attrs:{"id":"division-short-name-input"},model:{value:(form.values.short_name),callback:function ($$v) {_vm.$set(form.values, "short_name", $$v)},expression:"form.values.short_name"}})],1)],1),(_vm.division.is_project)?_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":_vm.$t('views.projectCreate.startDate'),"id":"start-date-input","message":form.errors.start_date,"type":form.types.start_date}},[_c('b-datepicker',{attrs:{"placeholder":_vm.$t('form.clickToSelect'),"value":form.values.start_date ? _vm.dateWithoutOffset(form.values.start_date) : null},on:{"input":function (value) {
                      form.values.start_date = value
                    }}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":_vm.$t('views.projectCreate.endDate'),"message":form.errors.end_date,"type":form.types.end_date,"id":"end-date-input"}},[_c('b-datepicker',{attrs:{"placeholder":_vm.$t('form.clickToSelect'),"value":form.values.end_date ? _vm.dateWithoutOffset(form.values.end_date) : null},on:{"input":function (value) {
                      form.values.end_date = value
                    }}})],1)],1)])]):_vm._e(),_c('div',{staticClass:"buttons is-right mt-4"},[(_vm.person.creators_delete_division.includes(_vm.division.id))?_c('b-button',{attrs:{"type":"is-danger"},on:{"click":_vm.callDeleteDivision}},[_vm._v(_vm._s(_vm.$t('editDivisionDetailHead.deleteButton')))]):_vm._e(),_c('b-button',{on:{"click":function($event){return _vm.$emit('doneEdit')}}},[_vm._v(_vm._s(_vm.$t('personEditProfile.cancelButton')))]),_c('b-button',{attrs:{"native-type":"submit","type":"is-azul-imfd","disabled":!!form.erorrs}},[_vm._v(_vm._s(_vm.$t('personEditProfile.saveButton'))+" ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }